<template>
    <div>
        <!-- 反馈管理 -->
        <!--    <headtitle Otitle="反馈管理" />-->
        <el-card class="box-card">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
                <el-breadcrumb-item>申报管理</el-breadcrumb-item>
                <el-breadcrumb-item>反馈管理</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="hr-20"></div>
            <!-- 搜索组件 -->
            <div class="fl">
                <div class="searchtool">
                    <el-form label-width="0" class="ruleForm" size="mini" inline>
                        <el-form-item>
                            <el-select v-model="ruleForm.type" placeholder="类型">
                                <el-option label="个人" value="talent"></el-option>
                                <el-option label="单位" value="enterprise"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="ruleForm.title" placeholder="请输入标题"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-date-picker
                                v-model="date1"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            ></el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="searchSubmit()" style="color: #f0f0f0">查询</el-button>
                            <el-button type="primary" plain size="mini" v-if="sehs" @click="requestRefresh"
                                       icon="el-icon-refresh">刷新
                            </el-button>
                            <el-button type="primary" plain size="mini" v-else @click="requestRefresh"
                                       icon="el-icon-refresh">返回列表
                            </el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <!--<el-button class="fr" type="warning" plain size="mini">导出Excel</el-button>-->
            <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                      @selection-change="handleSelectionChange">

                <el-table-column prop="policyTitle" label="政策标题" min-width="320px" align="left" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag type="danger" v-if="!scope.row.policyTitle">对应政策表单已被删除</el-tag>
                        <span type="info" v-else style="cursor: pointer"
                              @click="requestSeeDetails(scope.row)">{{ scope.row.policyTitle }}</span>
                    </template>

                </el-table-column>
                <el-table-column prop="ownerName" min-width="200px" align="center" label="申请人"></el-table-column>
                <el-table-column label="提交申报时间" min-width="240px" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.createTime |formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="状态" min-width="240px" align="center">
                    <template slot-scope="scope">
                        <div>
                            <el-button type="success" plain size="mini" v-if="scope.row.complete === 1">已办结</el-button>
                            <el-button type="info" plain size="mini" v-else>未办结</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="240px" align="center">
                    <template slot-scope="scope">
                        <el-button type="success" @click="lookopen(scope.row)" plain size="mini"
                                   icon="el-icon-zoom-in" v-if="scope.row.complete === 1">查看反馈
                        </el-button>
                        <el-tag type="info" v-else>待反馈</el-tag>
                    </template>
                </el-table-column>

            </el-table>
            <div class="hr-10"></div>
            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
                           @current-change="requestPage" :current-page="fcurrent" id="pagination" v-if="sehs"/>
            <el-pagination background layout="prev, pager, next" :total="total" :page-size="pageSize"
                           @current-change="searchPage" :current-page="fcurrent" v-else/>
            <span style="float: left;margin: -35px 0 0 0;">共计{{ total }}条数据</span>
        </el-card>


        <el-dialog title="反馈内容" :visible.sync="dialogVisible" width="560px" :before-close="lookhide">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="金额">
                    <el-input v-model="form.money"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input disabled type="textarea" v-model="form.remarks"></el-input>
                </el-form-item>
                <div style="margin-left: -220px">
                    反馈时间: {{ form.createTime | formatDate }}
                </div>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = !dialogVisible">关 闭</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
//import headtitle from "@/components/ControlPanel/headtitle";
//import fksearch from "@/components/ControlPanel/fksearch";

export default {
    components: {
        //headtitle,
        //fksearch
    },
    data() {
        return {
            sehs: true,
            dialogVisible: false,
            fankuistr: [],
            page: 1,
            fcurrent: parseInt(sessionStorage.getItem("fcurrent")) || 1,
            pages: 0,
            createTime: '',
            pageSize: 10,
            total: 1,
            tableData: [],
            multipleSelection: [],
            date1: "",
            ruleForm: {
                current: 1,
                size: 10,
                type: '',
                title: "",
                startTime: "",
                currentStatus: 21,
                endTime: ''
            },
            form: {
                money: "",
                remarks: "",
                createTime: '',
            },
            affairId: '',
            userId: '',
        };
    },
    created() {
        this.getData();
    },
    methods: {
        request() {
            let newPage = this.$router.resolve({
                name: 'EntryStoma',
            })
            window.open(newPage.href, '_blank')
        },
        requestRefresh() {
            this.date1 = "";
            this.fcurrent = 1
            this.ruleForm = {
                current: 1,
                size: 10,
                type: '',
                title: "",
                startTime: "",
                endTime: '',
                currentStatus: 21
            }
            this.getData();
            this.total = 1
        },
        //获取分页数据
        getData() {
            this.sehs = true;
            let self = this;
            this.$axios.get('/affair/page', {
                params: {
                    current: this.fcurrent,
                    size: 10,
                    currentStatus: 21,
                }
            }).then(function (response) {
                self.tableData = response.data.data.records;
                self.total = response.data.data.total
            })
        },

        /**
         * 查看详情
         * @param row object
         */
        requestSeeDetails(row) {
            sessionStorage.setItem("fcurrent", this.fcurrent);
            this.$router.push({path: "/feedbackDetails", query: {id: row.policyId}})
        },
        /**
         * 分页
         * @param value number
         */
        requestPage(value) {
            this.fcurrent = value;
            let self = this;
            this.$axios.get('/affair/page', {
                params: {
                    current: value,
                    size: 10,
                    currentStatus: 21
                }
            }).then(function (response) {
                self.tableData = response.data.data.records;
                self.total = response.data.data.total
            })
        },
        /**
         *  查询分页
         * @param value number
         */
        searchPage(value) {
            this.ruleForm.current = value
            this.$axios.get('/affair/page', {
                params: this.ruleForm
            }).then(response => {
                if (response.data.status === 200) {
                    this.tableData = response.data.data.records
                    this.total = response.data.data.total
                }
            })
        },

        handleEdit(index, row) {
        },
        lookopen(row) {
            this.dialogVisible = true
            this.$axios.get("/affair?id=" + row.id).then(response => {
                this.affairId = response.data.data.id;
                this.userId = response.data.data.ownerId;

                this.$axios.get("/affair/feedback?affairId=" + this.affairId + "&userId=" + this.userId).then(response => {
                    this.form.money = response.data.data.content.split('===备注：')[0]
                    this.form.remarks = response.data.data.content.split('===备注：')[1]
                    this.form.createTime = response.data.data.createTime
                })
            })
        },
        lookhide() {
            this.dialogVisible = false
            this.fankuistr = []
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },

        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //搜索
        searchSubmit() {
            let self = this
            this.ruleForm.startTime = this.date1[0] ? this.$moment(this.date1[0]).format('yyyy-MM-DD') : ''
            this.ruleForm.endTime = this.date1[1] ? this.$moment(this.date1[1]).format('yyyy-MM-DD') : ''
            this.$axios.get('/affair/page', {
                params: this.ruleForm
            }).then(function (response) {
                self.tableData = response.data.data.records
                self.total = response.data.data.total
                self.sehs = false
            })
        }
    }
};
</script>

<style lang="less" scoped>
.fankbox {
    width: 100%;

    li {
        text-align: left;
        font-size: 16px;
    }
}
</style>